import { writable, derived, get } from 'svelte/store';
import { browser } from '$app/environment';
import { goto } from '$app/navigation';
import { postsShow } from '$stores/posts';
import { query } from '$lib/search/stores';

export const postsPerPage = writable(10);
export const currentPage = writable(1);

export const paginatedPosts = derived(
  [postsShow, currentPage, postsPerPage],
  ([$postsShow, $currentPage, $postsPerPage]) => {
    const start = ($currentPage - 1) * $postsPerPage;
    const end = start + $postsPerPage;
    return $postsShow.slice(start, end);
  },
);

export const totalPages = derived([postsShow, postsPerPage], ([$postsShow, $postsPerPage]) =>
  Math.ceil($postsShow.length / $postsPerPage),
);

export function setPage(pageNumber: number) {
  currentPage.set(pageNumber);
  if (browser) {
    updateUrl(pageNumber, get(query));
  }
}

export function syncUrlToState(url: URL) {
  const pageParam = url.searchParams.get('page');
  const queryParam = url.searchParams.get('query');

  if (pageParam) {
    currentPage.set(parseInt(pageParam, 10));
  } else {
    currentPage.set(1);
  }

  if (queryParam) {
    query.set(queryParam);
  } else {
    query.reset();
  }

  // 確保在同步 URL 狀態後立即觸發 postsShow 的過濾
  postsShow.filter();
}

function updateUrl(pageNumber: number, currentQuery: string) {
  if (!browser) return;

  const url = new URL(window.location.href);
  url.searchParams.set('page', pageNumber.toString());
  if (currentQuery) {
    url.searchParams.set('query', currentQuery);
  } else {
    url.searchParams.delete('query');
  }
  goto(url.toString(), { replaceState: false, keepFocus: true, noScroll: true });
}

export function handlePopState() {
  if (browser) {
    const url = new URL(window.location.href);
    syncUrlToState(url);
  }
}
